<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button> </p>
            </div>
        </div>


        <div class="form-list">
            <el-form :model="dataSource" ref="_sysQueueLogForm">
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        请求平台：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="Platform">
                            <el-input v-model="dataSource.Platform" readonly :maxlength="30" :minlength="2"
                                placeholder="请求平台"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">
                        接口地址：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="Url">
                            <el-input v-model="dataSource.Url" readonly :maxlength="30" :minlength="2"
                                placeholder="接口地址"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        请求时间：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="RequestTime">
                            <el-input v-model="dataSource.RequestTime" readonly :maxlength="30" :minlength="2"
                                placeholder="请求时间"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">
                        应答时间：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="ResponseTime">
                            <el-input v-model="dataSource.ResponseTime" readonly :maxlength="30" :minlength="2"
                                placeholder="应答时间"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        数据流向：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="DisplayDataFlow">
                            <el-input readonly v-model="dataSource.DisplayDataFlow"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title">
                        处理结果：
                    </el-col>
                    <el-col :span="7">
                        <el-form-item prop="DisplayDealResult">
                            <el-input readonly v-model="dataSource.DisplayDealResult"  ></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        请求头：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="RequestContent">
                            <el-input readonly type="textarea" :autosize="{ minRows: 6, maxRows: 10 }"
                                v-model="dataSource.RequestHeader"  ></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        请求内容：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="RequestContent">
                            <el-input readonly type="textarea" :rows="5" v-model="dataSource.RequestContent"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">
                        请求结果：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="responseContent">
                            <el-input readonly type="textarea" :rows="10" v-model="responseContent"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <!-- <el-form-item>
                    <el-col :span="3" class="form-title">
                        处理描述：
                    </el-col>
                    <el-col :span="17">
                        <el-form-item prop="requestContent">
                            <el-input readonly type="textarea" v-model="requestContent" :rows="10"></el-input>
                        </el-form-item>
                        <p style="text-align: right;">
                            <el-link type="primary" @click="showSrc">原文</el-link> &nbsp;
                            <el-link type="primary" @click="jonsDeal">格式化</el-link> &nbsp;
                            <el-link type="primary" @click="urldecode">编码转义</el-link>
                        </p>
                    </el-col>
                </el-form-item> -->
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            requestContent: null,
            responseContent: null,
            isJsonBuef: true,
            isEncodeBuef: true,
        }
    },
    props: {
        config: {
            isDetailDisplay: false,
        },
        dataSource: {},

    },
    methods: {

        showSrc() {
            var _this = this;
            _this.requestContent = _this.dataSource.RequestContent;
            _this.responseContent = _this.dataSource.ResponseContent;
            _this.isJsonBuef = true;
            _this.isEncodeBuef = true;
        },
        jonsDeal() {
            var _this = this;
            if (_this.isJsonBuef) {
                _this.isJsonBuef = false;
                try {
                    var jsonObj = JSON.parse(_this.requestContent || '');
                    _this.requestContent = JSON.stringify(jsonObj, null, 4);
                } catch (e) {
                    _this.requestContent = _this.requestContent;
                }
                try {
                    jsonObj = JSON.parse(_this.responseContent || '');
                    _this.responseContent = JSON.stringify(jsonObj, null, 4);

                } catch (e) {
                    _this.responseContent = _this.responseContent;
                }

            }
            else {
                // _this.isJsonBuef = true;
                // _this.requestContent = _this.detailSource.RequestContent;
                // _this.responseContent = _this.detailSource.ResponseContent;
            }
        },
        urldecode() {
            var _this = this;
            if (_this.isEncodeBuef) {
                _this.isEncodeBuef = false;
                _this.requestContent = decodeURIComponent(_this.requestContent);
                _this.responseContent = decodeURIComponent(_this.responseContent);
            }

        }
    }
}
</script>
